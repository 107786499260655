import logoImg from "../assets/logo.png";
import { useState } from "react";
function Login() {
    const [formData, setFormData] = useState({ email: "" });
    const onSubmitHandler = (e) => {
        e.preventDefault();
        alert("OTP sent to your registered email address");
    }
    const onChangeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    return (
        <>
            <div className="relative min-h-screen">
                <div className="absolute inset-0 w-full h-full bg-gray-100 z-10 bg-opacity-90"></div>
                <div className="bg-bottom bg-no-repeat flex flex-col justify-center relative bg-90%">
                    <div className="z-20 min-h-screen flex justify-center items-center gap-[20%]">
                        <img src={logoImg} alt="" className="hidden lg:block w-[12%]" />
                        <div className="m-5 w-full md:w-1/3 lg:w-1/3">
                            <div role="alert" className="bg-[#a4e4e0] px-4 py-3">
                                <p className="font-bold">Panaroma Intelligence Solutions</p>
                                <p className="text-sm">Access is limited to Panaroma clients only. © Panaroma. All rights reserved.</p>
                            </div>
                            <div className="bg-white rounded-xl p-8 py-12 shadow-2xl">
                                <h1 className="text-3xl font-bold mb-12 text-center lg:text-4xl">CallHealth Login </h1>
                                <form method="post" onSubmit={onSubmitHandler}>
                                    <div className="flex flex-col mb-3 gap-1">
                                        <label className="label"><span className="text-lg font-semibold lg:text-1xl">Enter Registered Email Address</span></label>
                                        <div className="flex text-center">
                                            <input
                                                type="email" name="email"
                                                placeholder="Email or Phone Number"
                                                className="px-2 border-solid border-4 w-full border-slate-200 text-xl rounded-[8px] py-[10px] focus:outline-none"
                                                autoComplete="off"
                                                value={formData.email}
                                                onChange={onChangeHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-center mt-8">
                                        <button className="w-full py-2 text-black text-2xl font-bold rounded-md bg-[#a4e4e0]">Send OTP</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}


export default Login;