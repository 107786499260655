import './index.css';
import Login from "./components/Login";
import Footer from "./components/Footer";
function App() {
  return (
    <>
      <div>
        <Login />
        <Footer />
      </div>
    </>
  );
}

export default App;
